.label-input {
  min-width: 60px;
}

.input-field {
  position: relative;
  width: 100%;
  height: 40px;
}
.input-field input {
  height: 100%;
  width: 100%;
  outline: none;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 15px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 15px;
  box-shadow: 0 1px 6px 0 #20212447;
  border: none;
  background-color: hsla(0, 0%, 100%, 0.919);
  resize: none;
  margin: 0px 10px;
}
.input-field input:focus {
  box-shadow: 0 1px 6px 0 #20212447;
  border: 1px solid #ccc;
}

@media (max-width: 280px) {
  .label-input {
    min-width: 55px;
  }
  .input-field {
    height: 25px;
    max-width: 80%;
  }
  .input-field input {
    font-size: 13px;
    padding: 10px;
    margin: 0px 8px;
  }
}
