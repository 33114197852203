/*  DARKMODE ----------- */
.title {
  color: var(--color-brand);
}
/*--------------------- */
.add-button {
  background-color: #853b72;
  font-size: 22px;
  border-radius: 50px;
  min-width: 40px;
  margin: 0px 0px 0px 20px;
  width: fit-content;
  padding: 7px 14px 0 10px;
  box-shadow: 2px 2px 6px 2px #20212447;
}
.add-button:hover {
  background-color: #a64c8e;
  box-shadow: none;
}
@media screen and (max-width: 540px) {
  .add-button {
    padding: 7px 14px 0 9px;
  }
}
@media screen and (max-width: 393px) {
  .add-button {
    font-size: 17px;
    min-width: 32px;
    margin: 0px 0px 0px 15px;
    padding: 5px 7px 0px 7px;
  }
}

@media (max-width: 280px) {
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .add-button {
    font-size: 15px;
    min-width: 29px;
    margin: 5px 0px 5px 15px;
    padding: 5px 6px 0 6px;
  }
}

@media (max-width: 279px) {
  .add-button::after {
    padding: 5px 20px 8px 5px;
    margin: 5px 0px 5px 5px;
    content: "Add title";
  }
}
