/*  DARKMODE ----------- */
.title {
  color: var(--color-brand);
}
/*--------------------- */
.search-input {
  padding-right: 62px;
}

input[type="search"] {
  background-image: url(../assets/icon-search.svg);
  background-position: 8px 8px;
  background-repeat: no-repeat;
  padding: 0 10px 0 37px;
}
@media screen and (max-width: 393px) {
  .search-input {
    padding-right: 45px;
  }
}
@media (max-width: 280px) {
  input[type="search"] {
    background-position: 3px 1px;
    padding: 0 10px 0 28px;
    min-width: 206px;
  }
}

@media (max-width: 279px) {
  input[type="search"] {
    background-position: 3px 1px;
    padding: 0 10px 0 28px;
    min-width: 100%;
  }
  .search-input {
    padding-right: 0;
  }
}
