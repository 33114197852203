.ListItem {
  height: calc(100% - (69px + 92px));
}
@media screen and (max-width: 540px) {
  .ListItem {
    height: calc(100% - (69px + 92px));
  }
}
@media screen and (max-width: 393px) {
  .ListItem {
    height: calc(100% - (69px + 77px));
  }
}
@media (max-width: 280px) {
  .ListItem {
    height: calc(100% - (69px + 65px));
  }
}
