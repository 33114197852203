/*  DARKMODE ----------- */
.dark-mode {
  background: var(--gm-background-glassmorph);
}
.dark-mode .header {
  color: var(--color-brand);
}
.dark-mode .ListItem div h2,
.dark-mode .ListItem div p {
  color: #000000;
}
.dark-mode .about-wrapper div {
  background-color: hsla(0, 0%, 100%, 0.719);
}
/*--------------------- */

.ListItem {
  height: calc(100% - 115px);
  padding-left: 20px;
}
.about-wrapper div {
  background-color: var(--gm-background);
  border-radius: 15px;
  padding: 15px 15px 15px 15px;
  margin-bottom: 15px;
}
.about-wrapper h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #000000;
}

.about-wrapper p {
  font-size: 16px;
  line-height: 1.5;
  color: #000000;
}
@media screen and (max-width: 393px) {
  .ListItem {
    height: calc(100% - 101px);
    padding-left: 20px;
  }
}
@media (max-width: 280px) {
  .ListItem {
    height: calc(100% - 82px);
    padding-left: 20px;
  }
}
