.primary-header {
  align-items: center;
  justify-content: space-between;
}

.app-name-wrapper {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: bold;
  font-size: x-large;
}
.logo {
  margin: 1rem;
  width: 44px;
  height: 44px;
}
.app-name {
  color: #ffffff;
}
.mobile-nav-toggle {
  display: none;
}
.primary-navigation {
  list-style: none;
  color: #ffffff;
  padding: 0;
  margin: 0;
  letter-spacing: 0.2rem;
  mask-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 10%,
    #ffffff 90%,
    rgba(255, 255, 255, 0) 100%
  );
  background-image: linear-gradient(
    to right,
    #fe92c4,
    #e981bc,
    #d470b4,
    #bd61ac,
    #a652a5,
    #a652a5,
    #a652a5,
    #a652a5,
    #bd61ac,
    #d470b4,
    #e981bc,
    #fe92c4
  );
  padding: 1rem;
  --gap: 3rem;
  padding-inline: clamp(3rem, 10vw, 10rem);
}
.primary-navigation li a {
  text-decoration: none;
  color: #ffffff;
}
.primary-navigation li a:hover {
  color: #000000;
}
@supports (backdrop-filter: blur(3rem)) {
  .primary-navigation {
    mask-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 10%,
      #ffffff 90%,
      rgba(255, 255, 255, 0) 100%
    );
    backdrop-filter: blur(3rem);
    background-color: rgba(133, 65, 155, 0.921);
  }
}
.active {
  text-transform: uppercase;
}
.flex {
  display: flex;
  gap: var(--gap, 1rem);
}
@media screen and (max-width: 540px) {
  .primary-navigation {
    mask-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 10%,
      #ffffff 5%
    );
    background-image: linear-gradient(
      to bottom,
      hsla(285, 41%, 43%, 0.9),
      hsl(295, 37%, 47%, 0.9),
      hsl(305, 38%, 53%, 0.9),
      hsl(314, 50%, 60%, 0.9),
      hsl(320, 66%, 67%, 0.9),
      hsl(328, 77%, 71%, 0.9),
      hsl(335, 88%, 74%, 0.9),
      hsl(342, 100%, 78%, 0.8),
      hsl(352, 100%, 79%, 0.7),
      hsl(3, 100%, 80%, 0.5),
      hsl(12, 95%, 78%, 0.4),
      hsl(20, 85%, 77%, 0.3)
    );
  }
  .primary-navigation li a {
    text-decoration: none;
    color: #ffffff;
  }
  .primary-navigation li a:hover {
    color: #000000;
  }
  @supports (backdrop-filter: blur(3rem)) {
    .primary-navigation {
      mask-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 10%,
        #ffffff 5%
      );
      backdrop-filter: blur(3rem);
      background-color: rgba(133, 65, 155, 0.571);
    }
  }

  .primary-navigation {
    --gap: 2em;

    position: fixed;
    z-index: 1000;
    inset: 0 0 0 30%;

    flex-direction: column;
    padding: min(15vh, 6rem) 1em min(15vh, 6rem) 5em;
    transition: transform 350ms ease-out;
    transform: translateX(0%);
  }
  .primary-navigation-hidden {
    transform: translateX(100%);
  }
  .mobile-nav-toggle {
    display: block;
    position: absolute;
    z-index: 9999;
    background-color: transparent;
    color: #660d55;
    background-image: url("../assets/close.svg");
    background-repeat: no-repeat;
    width: 2rem;
    box-shadow: none;
    margin-left: none;
    padding: none;
    border-radius: none;
    transition: none;
    aspect-ratio: 1;
    top: 2rem;
    right: 2rem;
  }

  .mobile-nav-toggle:hover {
    box-shadow: none;
  }
  .mobile-nav-toggle:active {
    transform: none;
    box-shadow: none;
  }
  .mobile-nav-toggle-close {
    background-image: url("../assets/hamburger-menu.svg");
  }
}
@media screen and (max-width: 393px) {
  .navigation {
    align-items: center;
    flex-direction: column;
  }
  .primary-navigation {
    padding: min(15vh, 6rem) 0 min(15vh, 6rem) 4em;
  }
}

@media (max-width: 280px) {
  .navigation {
    align-items: center;
    flex-direction: column;
  }
}
