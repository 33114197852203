/*  DARKMODE ---------- */
.dark-mode {
  background: var(--gm-background-glassmorph);
}
.dark-mode .header {
  color: var(--color-brand);
}
/*--------------------- */

.header {
  margin-top: 20px;
  padding-left: 10px;
}

.create-list-button {
  background-color: #853b72;
  font-size: 22px;
  margin: 0px 0px 0px 20px;
  padding: 7px 10px 0 10px;
  box-shadow: 2px 2px 6px 2px #20212447;
}
.create-list-button:hover {
  background-color: #a64c8e;
  box-shadow: none;
}
@media (max-width: 280px) {
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .create-list-button {
    font-size: 15px;
    margin: 0px 0px 0px 20px;
    padding: 5px 8px 0 8px;
  }
}
