.glassmorph {
  background: var(--gm-background-glassmorph);
  box-shadow: var(--gm-box-shadow);
  border: var(--gm-border);
  border-top: var(--gm-border-side);
  border-left: var(--gm-border-side);
}
@supports (backdrop-filter: blur(10px)) {
  .glassmorph {
    backdrop-filter: var(--gm-bg-filter);
  }
}

.button {
  background-color: #853b72;
  font-weight: bold;
  display: inline-block;
  color: #ffffff;
  transition: 0.3s;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 15px;
  display: flex;
  place-content: center;
  box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.5),
    5px 5px 10px rgba(0, 0, 70, 0.12);
}

.button:hover {
  background-color: var(--gm-background-hover);
  box-shadow: none;
}

.addNotes {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 222px;
  border-radius: 10px;
  padding: 1rem;
  white-space: pre-wrap;
}

.addNotes-text {
  font-size: 0.9rem;
  resize: none;
  background: transparent;
  border: 0;
}

.addNotes-text:focus {
  outline: none;
}

.addNotes-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addNotes-footer-chars {
  font-size: small;
}

.addNotes-footer-btn {
  font-size: 0.8rem;
  cursor: pointer;
  transition: 0.3s;
}

.addNotes-footer-btn:hover {
  color: #ffffff;
}

/* DARKMODE ---------------------*/

.dark-mode .button {
  color: white;
}

.dark-mode .addNotes {
  background: var(--color-add-notes);
  box-shadow: 0 1px 6px 0 #20212447;
}

.dark-mode .addNotes-footer-btn {
  background: var(--color-btn);
  color: black;
}

.dark-mode .addNotes-footer-btn:hover {
  background: var(--color-btn-alt);
} /*--------------------------------*/
