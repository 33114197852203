.table-names-list {
  text-decoration: none;
  margin-left: 10px;
  letter-spacing: 0.1rem;
  opacity: 1;
}

.table-names-list:hover {
  opacity: 0.6;
}

.table-list-element {
  padding: 5px 20px 5px 20px;
}

.arrow {
  padding: 1px;
  width: 25px;
  height: 25px;
  position: absolute;
  right: 16px;
}
.list {
  min-width: 82%;
}

@media screen and (max-width: 540px) {
  .list {
    min-width: 81%;
  }
}
@media screen and (max-width: 393px) {
  .list {
    min-width: 78%;
  }
}
@media (max-width: 280px) {
  .table-list-element {
    padding: 0px 20px 0px 20px;
  }
  .list {
    min-width: 75%;
  }
}
