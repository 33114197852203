.notes-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1rem;
  max-width: 600px;
  place-content: start space-evenly;
}

.ListItem {
  height: calc(100% - (115px));
  padding: 0 10px 20px 20px;
}
@media screen and (max-width: 393px) {
  .ListItem {
    height: calc(100% - 99px);
    padding-left: 20px;
  }
}
@media (max-width: 280px) {
  .ListItem {
    height: calc(100% - 81px);
    padding-left: 20px;
  }
}
