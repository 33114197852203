.edit-text-label {
  padding: 10px;
  font-size: 12px;
  color: #39191e;
  position: absolute;
  top: 0px;
}

.cancel-button {
  background-color: transparent;
  border: none;
  color: #39191e;
  font-size: 20px;
  position: absolute;
  margin: 8px 10px 0 0;
  top: 0px;
  right: 0px;
  max-height: 18px;
  max-width: 18px;
  height: fit-content;
  width: fit-content;
  padding: 0px 1.5px 0px 1.5px;
  margin-right: 10px;
  box-shadow: none;
}

.cancel-button:hover {
  color: #692f37;
  box-shadow: none;
}
.cancel-button:active {
  color: #b94c9e;
  box-shadow: none;
}
.edit-input {
  border: none;
  background-color: hsla(34, 88%, 93%, 0.5);
  border-radius: 15px;
  outline: none;
  width: 100%;
  padding: 10px 10px;
  box-sizing: border-box;
  color: #2f2f2f;
  font-size: 1rem;
  resize: none;
  overflow: hidden;
  white-space: normal;
  position: relative;
  margin-top: 20px;
}

.edit-input:focus {
  background-color: hsla(34, 88%, 93%, 0.5);
  border-radius: 15px;
  outline: none;
}

.edit-input:hover {
  background-color: hsla(34, 88%, 93%, 0.5);
  box-shadow: 0 1px 6px 0 #20212447;
  border-radius: 15px;
  outline: none;
}
.title-checked {
  font-size: 1rem;
  color: #838383;
  text-decoration: line-through;
}

.save-button {
  background-color: #fe83a1;
}

.save-button:hover {
  background-color: #f85b83;
}
.edit-button {
  background-color: #be53a3;
}

.edit-button:hover {
  background-color: #be3a9d;
}
.delete-button {
  background-color: #ff8ec4;
}

.delete-button:hover {
  background-color: #f458a3;
}
.created-time {
  font-size: small;
  color: grey;
}
.checkbox-wrapper *,
.checkbox-wrapper ::after,
.checkbox-wrapper ::before {
  box-sizing: border-box;
  transform: scale(0.7);
}

.checkbox-wrapper .container input {
  opacity: 1;
  appearance: none;
  cursor: pointer;
  height: 50px;
  width: 50px;
  box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
    10px 10px 15px rgba(0, 0, 70, 0.12);
  border-radius: 1em;
  border: 8px solid #d4d4d4;
  background-color: #ffffff;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}

.checkbox-wrapper .container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox-wrapper .container input::after {
  transition: 0.5s;
  font-family: monospace;
  content: "";
  color: #7a7a7a;
  font-size: 25px;
  left: 0.45em;
  top: 0.25em;
  width: 0.25em;
  height: 0.5em;
  border: solid #7a7a7a;
  border-width: 0 0.15em 0.15em 0;
  transform: rotate(45deg);
}

.checkbox-wrapper .container input:checked {
  box-shadow: 10px 10px 15px rgba(70, 70, 70, 0.12),
    inset -10px -10px 15px rgba(255, 255, 255, 0.5),
    inset 10px 10px 15px rgba(70, 70, 70, 0.12);
  background-color: #853b72;
  transition: 0.5s;
}

.checkbox-wrapper .container input:checked::after {
  transition: 0.5s;
  border: solid #ffffff;
  border-width: 0 0.15em 0.15em 0;
  transform: rotate(45deg);
}
@media screen and (max-width: 393px) {
  .check-circle-checked {
    margin-right: 5px;
  }
  .check-circle {
    margin-right: 5px;
  }
  .edit-text-label {
    padding: 5px;
    font-size: 13px;
    top: 0px;
  }
  .cancel-button {
    margin: 8px 10px 0 0;
    max-height: 16px;
    max-width: 16px;
    padding: 0px 1px 1px 1px;
    margin-right: 10px;
  }
  .edit-input {
    padding: 10px 10px;
    font-size: 1rem;
    margin-top: 15px;
  }
}
@media (max-width: 280px) {
  .checkbox-wrapper *,
  .checkbox-wrapper ::after,
  .checkbox-wrapper ::before {
    transform: scale(0.8);
    max-width: 30px;
    max-height: 30px;
  }
  .checkbox-wrapper .container input {
    border: 4px solid #d4d4d4;
  }

  .check-circle-checked {
    padding: 1px 3px 0px 3px;
    margin-right: 3px;
  }

  .check-circle {
    padding: 1px 3px 0px 3px;
    margin-right: 3px;
  }

  .edit-text-label {
    padding: 5px;
    font-size: 10px;
    top: 0px;
  }
  .cancel-button {
    margin: 8px 10px 0 0;
    max-height: 12px;
    max-width: 12px;
    padding: 0px 1px 0px 1px;
    margin-right: 8px;
    font-size: 12px;
  }
  .edit-input {
    padding: 10px 10px;
    font-size: 0.8rem;
    margin-top: 15px;
  }
  .title-checked {
    font-size: 13px;
  }
}
