.notes-footer-icon {
  color: #000000;
  font-size: 1.2rem;
  cursor: pointer;
  transition: 0.3s;
}

.notes-footer-icon:hover {
  color: gray;
}
.delete-button {
  background-color: transparent;
  padding: 9px 5px;
  box-shadow: none;
}

.delete-button:hover {
  background-color: transparent;
  box-shadow: none;
}
.notes {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 222px;
  border-radius: 10px;
  padding: 1rem;
  white-space: pre-wrap;
}

.notes-text {
  font-size: 0.9rem;
}

.notes-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notes-footer-date {
  font-size: small;
  color: grey;
}

/*  DARKMODE ------------- */
.dark-mode .notes {
  background: var(--color-notes);
  box-shadow: 0 1px 6px 0 #20212447;
}
/*   --------------------- */

.glassmorph {
  background: var(--gm-background);
  box-shadow: var(--gm-box-shadow);
  border: var(--gm-border);
  border-top: var(--gm-border-side);
  border-left: var(--gm-border-side);
}
@supports (backdrop-filter: blur(10px)) {
  .glassmorph {
    backdrop-filter: var(--gm-bg-filter);
  }
}
