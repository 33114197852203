/*  DARKMODE ---------- */
.dark-mode {
  background: var(--gm-background-glassmorph);
}
.dark-mode .header {
  color: var(--color-brand);
}
.title {
  color: var(--color-brand);
}
/*--------------------- */
.header {
  padding-left: 10px;
}
.top-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 280px) {
  .top-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 30px;
  }
}
