/* Google Fonts - Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
:root {
  /* Colors  */
  --color-btn: #e1e1e1;
  --color-btn-alt: #ededed;
  --color-brand: #39191e;
  --color-notes: hsl(46, 84%, 90%);
  --color-add-notes: hsl(342, 100%, 86%);

  /* GlassMorph effect  */
  --gm-background-glassmorph: rgba(255, 255, 255, 0.206);
  --gm-background: hsla(0, 0%, 100%, 0.719);
  --gm-background-hover: hsl(316, 37%, 47%);
  --gm-box-shadow: 0 15px 35px rgba(0, 0, 0, 0.05);
  --gm-bg-filter: blur(10px);
  --gm-border: 1px solid rgba(255, 255, 255, 0.25);
  --gm-border-side: 1px solid rgba(255, 255, 255, 0.5);
}

body {
  background-image: url("../images/desktop-background.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  overflow-x: hidden;
}

/*The CSS class "sr-only" is used to hide element visually from
sighted users while still making them accessible to assistive
technologies such as screen readers (SR), making website more 
inclusive for all users. That's why the name of the class is 
"sr-only", which means "for Screen Readers only". 
*/
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
/* For Scrollbar ----------*/

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background: #ccc;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}
/* -------------------------*/
.header {
  font-size: 2rem;
  color: #ffffff;
  font-weight: bold;
  padding: 0 10px 10px 25px;
}

.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 1rem 0 1rem;
}

.container {
  max-width: 600px;
  width: 100%;
  border-radius: 15px;
  padding: 0 10px 0 0;
  margin: 50px auto 0;
  position: absolute;
  top: 50px;
  bottom: 50px;
  left: 0;
  right: 0;
  background-color: rgba(242, 242, 242, 0.6);
  background-image: linear-gradient(
    to bottom,
    hsl(285, 41%, 43%, 0.9),
    hsl(323, 42%, 51%, 0.8),
    hsl(344, 57%, 62%, 0.7),
    hsl(3, 68%, 71%, 0.6),
    hsl(18, 80%, 75%, 0.5),
    hsl(20, 85%, 77%, 0.5),
    hsl(21, 93%, 79%, 0.5),
    hsl(23, 100%, 81%, 0.5),
    hsl(14, 100%, 80%, 0.4),
    hsl(359, 100%, 81%, 0.3),
    hsl(343, 100%, 78%, 0.2),
    rgba(255, 133, 198, 0.1)
  );
  box-shadow: 1px 4px 65px 6px rgba(133, 65, 155, 0.62);
  -webkit-box-shadow: 1px 4px 65px 6px rgba(133, 65, 155, 0.62);
  -moz-box-shadow: 1px 4px 65px 6px rgba(133, 65, 155, 0.62);
  overflow: hidden;
}

.input-with-button {
  display: flex;
  align-items: center;
  padding: 0 25px 10px 25px;
}

.title {
  font-size: 1rem;
  color: #ffffff;
}
.title-item {
  font-size: 1rem;
  color: #000000;
}

button {
  outline: none;
  color: #fff;
  border: none;
  padding: 9px 14px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.5s all;
  font-size: 15px;
  margin-left: 10px;
  box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.5),
    5px 5px 10px rgba(0, 0, 70, 0.12);
}

button:hover {
  box-shadow: inset 0px 11px 8px -10px #ccc, inset 0px -11px 8px -10px #ccc;
}

button:active {
  transform: scale(0.98);
  box-shadow: 2px 2px 6px 2px #20212447;
}

.ListItem {
  overflow: scroll;
  height: calc(100% - (69px + 170px));
  padding: 0 5px 10px 0;
}

.list {
  align-items: center;
  justify-content: space-between;
  list-style: none;
  width: 100%;
  background-color: hsla(0, 0%, 100%, 0.919);
  padding: 10px 15px 15px 15px;
  border-radius: 15px;
  color: #2f2f2f;
  font-size: 1rem;
  position: relative;
  max-width: 411px;
  white-space: normal;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.ListItem .list .title {
  width: 100%;
  white-space: normal;
}
.list-with-button {
  display: flex;
  align-items: center;
  padding: 10px 10px 0 10px;
}

.container .pending-tasks,
p {
  margin: 5px 0 5px 25px;
}
.pending-tasks,
p {
  color: #39191e;
}
.pending-num {
  color: #000000;
}
@media screen and (max-width: 540px) {
  .ListItem {
    overflow: scroll;
    height: calc(100% - (289px));
    padding: 0;
  }
  .container {
    margin: 50px auto 0;
    top: 50px;
    bottom: 0;
  }
}
@media screen and (max-width: 393px) {
  .container {
    margin: 50px auto 0;
    top: 50px;
    bottom: 0;
  }
  .header {
    font-size: 1.5rem;
    padding: 0px 0px 8px 20px;
  }

  button {
    padding: 4.5px 9px;
    box-shadow: 1.5px 1.5px 4px 1.5px #20212447;
    font-size: 14px;
    margin-left: 5px;
  }

  button:active {
    box-shadow: 1.5px 1.5px 4px 1.5px #20212447;
  }

  .ListItem {
    overflow: scroll;
    height: calc(100% - (69px + 157px));
    padding: 0;
  }
  .ListItem .list {
    padding: 10px 12px 10px 12px;
    font-size: 1rem;
    max-width: 235px;
  }

  .list-with-button {
    padding: 10px 10px 0 10px;
  }
}
@media (max-width: 280px) {
  .container {
    padding: 5px 2px 0 0;
    margin: 40px auto 0;
    top: 40px;
    bottom: 0;
  }
  .header {
    font-size: 1.1rem;
    padding: 0px 0px 0px 20px;
  }
  .title-item {
    font-size: 0.8rem;
  }
  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px 12px 0 12px;
  }
  .input-with-button {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 5px 10px;
  }

  .title {
    font-size: 13px;
  }

  .pending-tasks,
  p {
    font-size: 13px;
  }

  button {
    padding: 4.5px 8px;
    box-shadow: 1px 1px 3px 1px #20212447;
    font-size: 10px;
    margin-left: 3px;
  }

  button:active {
    box-shadow: 1px 1px 3px 1px #20212447;
  }

  .ListItem {
    overflow: scroll;
    height: calc(100% - (69px + 138px));
    padding: 0;
  }

  .ListItem .list {
    padding: 8px 12px 8px 12px;
    font-size: 1rem;
    max-width: 170px;
  }

  .list-with-button {
    padding: 5px 5px 0 5px;
  }

  .container .pending-tasks {
    margin: 0 0 0 10px;
  }
}
