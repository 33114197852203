/*  DARKMODE ----------- */
.dark-mode {
  background: var(--gm-background-glassmorph);
}
.dark-mode .header {
  color: var(--color-brand);
}
.dark-mode .ListItem div h2,
.dark-mode .ListItem div p {
  color: #000000;
}
.dark-mode .not-found-wrapper div {
  background-color: hsla(0, 0%, 100%, 0.719);
}
/*--------------------- */
.not-found-wrapper div {
  background-color: var(--gm-background);
  border-radius: 15px;
  padding: 15px 15px 15px 15px;
  margin: 20px 5px 20px 20px;
}
.not-found-wrapper h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #000000;
}
.img-page-not-found {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ListItem {
  height: calc(100% - (116px));
}
@media screen and (max-width: 540px) {
  .ListItem {
    height: calc(100% - (116px));
  }
}
@media screen and (max-width: 393px) {
  .ListItem {
    height: calc(100% - (101px));
  }
}
@media (max-width: 280px) {
  .ListItem {
    height: calc(100% - (82px));
  }
}
