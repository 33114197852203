.dropdown {
  position: relative;
  display: inline-block;
  max-width: 24px;
  max-height: 24px;
  margin-right: 5px;
}
.dropdown-btn {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #ffffff;
  font-size: 10px;
  cursor: pointer;
  margin: 0;
  top: 0px;
  right: 0px;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 0;
}
/*  DARKMODE --------- */
.dark-mode {
  color: #2f2f2f;
}
/*-------------------- */
.dropdown-btn:hover {
  color: #a3a3a3;
  box-shadow: none;
}
.dropdown-btn:active {
  color: #000000;
  transform: scale(0.98);
  box-shadow: none;
}

.dropdown ul {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1;
  display: none;
  margin: 0;
  padding: 0;
  min-width: 12.5rem;
  list-style: none;
  background-color: #fffefe;
  border-radius: 15px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown ul.show-menu {
  display: block !important;
  border-radius: 15px;
}

.dropdown li {
  padding: 12px 16px;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: hsla(34, 88%, 93%, 0.5);
  border-radius: 15px;
}

.arrow-up-down {
  padding: 1px;
  width: 25px;
  height: 25px;
  position: absolute;
  right: 16px;
}
@media (max-width: 280px) {
  .dropdown {
    margin-right: 18px;
  }
}
